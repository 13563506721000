import {
  UPDATE_USER,
  USER_CREATED,
  USER_SUCCESSFULLY_LOGGED_IN,
  USER_SUCCESSFULLY_LOGGED_OUT,
  IS_LOGGING_IN,
  LOGOUT
} from "../actions";
import { default as userModel } from "../models/user";

const initialState = {
  user: userModel,
  isLoggedIn: false,
  isLoggingIn: false
};

const removeIndex = index => (acc, item, i) => {
  if (i === index) return acc;
  return [...acc, item];
};

function user(state = initialState, action) {
  switch (action.type) {
    case UPDATE_USER: {
      const { user } = action.payload;
      return {
        ...state,
        user: { ...state.user, ...user }
      };
    }
    case USER_CREATED:
      return {
        ...state,
        user: { ...state.user, ...action.payload.user }
      };
    case IS_LOGGING_IN:
      return { ...state, isLoggingIn: true };
    case USER_SUCCESSFULLY_LOGGED_IN: {
      return {
        ...state,
        user: { ...action.payload.user },
        userLoaded: true,
        isLoggingIn: false,
        isLoggedIn: true
      };
    }
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default user;
