import React from "react";
import Nav from "../components/Nav";
import Header from "../components/Header";
import { makeStyles } from "@material-ui/core/styles";
import Copyright from "../components/shared/Copyright";
import { useUser } from "../hooks/user";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    minHeight: "100vh"
  },
  app: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    background: "#E5E5E5"
  },
  main: {
    flex: 1,
    padding: theme.spacing(6, 4)
  },
  footer: {
    padding: theme.spacing(2)
  }
}));

const DashboardLayout = ({ children, navigate }) => {
  const classes = useStyles();
  const user = useUser();

  return (
    <div className={classes.root}>
      <nav>
        <Nav />
      </nav>
      <div className={classes.app}>
        <Header navigate={navigate} />
        <main className={classes.main}>
          {!user.confirmed && (
            <div>Email verification required. Resend email</div>
          )}
          {children}
        </main>
        <footer className={classes.footer}>
          <Copyright />
        </footer>
      </div>
    </div>
  );
};

export default DashboardLayout;
