import React, { useState } from "react";
import SearchResult from "../components/SearchResult";

const Providers = props => {
  const [isEditing, setIsEditing] = useState(false);

  return <SearchResult />;
};

export default Providers;
