import React from "react";
import { default as BecomeAProviderForm } from "../components/BecomeAProvider";
import NotificationItem, {
  NotificationTypes
} from "../components/NotificationItem";
import { Grid } from "@material-ui/core";

const BecomeAProvider = props => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <NotificationItem
          title="Become a Provider"
          message="Fill in the fields below to complete your profile. Mandatory fields are marked with an asterisk *. Once your profile is complete you will be able to start hosting calls and collecting payment."
          type={NotificationTypes.NONE}
        />
      </Grid>
      <Grid item xs={12}>
        <BecomeAProviderForm />
      </Grid>
    </Grid>
  );
};

export default BecomeAProvider;
