import { useSelector } from "react-redux";

export const useProviders = () => {
  const providers = useSelector(
    ({ providers: { providers } }) => providers || []
  );
  return providers;
};

export const useProvidersByUsername = username => {
  const providers = useSelector(
    ({ providers: { providers } }) => providers || []
  );
  return providers.find(provider => provider.username === username);
};
