import React, { useEffect, useState } from "react";
import { Router as ReactRouter } from "@reach/router";
import { Route } from "./helpers/route";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";

import {
  fetchProviders,
  fetchNotifications,
  fetchAppointments,
  fetchMessages,
  fetchConversations,
  logoutUser,
  initialize
} from "./actions";

import Logout from "./pages/Logout";

// Pages
import Notifications from "./pages/Notifications";
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import Profile from "./pages/Profile";
import Meetings from "./pages/Meetings";
import Messages from "./pages/Messages";
import Settings from "./pages/Settings";
import Subscriptions from "./pages/Subscriptions";
import SearchResults from "./pages/SearchResults";
import BecomeAProvider from "./pages/BecomeAProvider";
import ForgotPassword from "./pages/ForgotPassword";
import Providers from "./pages/Providers";
import Provider from "./pages/Provider";

import Video from "./pages/Video";
import ProviderReceiveRequest from "./pages/ProviderReceiveRequest";
import ProviderSendInvite from "./pages/ProviderSendInvite";

// layouts
import MainLayout from "./layouts/Main";
import DashboardLayout from "./layouts/Dashboard";
import VideoChatLayout from "./layouts/VideoChat";

import withSplashScreen from "./hoc/withSplashScreen";

import auth from "./helpers/auth";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%"
  },
  app: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    background: "#E5E5E5"
  },
  main: {
    flex: 1,
    padding: theme.spacing(6, 4)
  },
  footer: {
    padding: theme.spacing(2)
  }
}));

function App(props) {
  const classes = useStyles();
  const [showWelcomeScreen, setShowWelcomeScreen] = useState(true);

  const { isLoggedIn, initialize, originalPath } = props;

  useEffect(() => {
    if (isLoggedIn) {
      props.fetchProviders();
      props.fetchNotifications();
      props.fetchMessages();
      props.fetchAppointments();
      props.fetchConversations();
    }

    setTimeout(() => {
      setShowWelcomeScreen(false);
    }, 2000);

    if (auth.isAuthenticated()) {
      initialize(originalPath === "/login" ? "/dashboard" : originalPath);
    }
  }, [isLoggedIn, originalPath, initialize]);

  return (
    <ReactRouter className={classes.root}>
      <Logout path="/logout" />

      <MainLayout path="/">
        <Login default path="/login" />
        <Registration path="/register" />
        <ForgotPassword path="/forgot-password" />
      </MainLayout>

      <DashboardLayout path="/dashboard">
        <Route component={Profile} path="/profile" isPrivate />
        <Route component={Provider} path="/providers/:id" isPrivate />
        <Route component={Providers} path="/providers" isPrivate />
        <Route component={Notifications} path="/notifications" isPrivate />
        <Route component={Subscriptions} path="/subscriptions" isPrivate />
        <Route component={SearchResults} path="/search-results" isPrivate />
        <Route component={Messages} path="/messages" isPrivate />
        <Route component={Meetings} path="/meetings" isPrivate />
        <Route component={Settings} path="/settings" isPrivate />
        <Route component={VideoChatLayout} path="/chat" isPrivate />
        <Route
          component={BecomeAProvider}
          path="/become-a-provider"
          isPrivate
        />
      </DashboardLayout>
    </ReactRouter>
  );
}

const NotFound = () => <div>Not Found</div>;

const mapState = ({ user: { user = {}, isLoggedIn } }) => ({
  isLoggedIn
});

export default withSplashScreen(
  connect(
    mapState,
    {
      fetchProviders,
      fetchNotifications,
      fetchMessages,
      fetchAppointments,
      fetchConversations,
      logoutUser,
      initialize
    }
  )(App)
);
