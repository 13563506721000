import React from "react";
import axios from "axios";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";

import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";

const Calendar = () => {
  const handleNewMeetingClick = () => {
    //open meeting modal
    const date = "";
    //alert("open meeting modal");
    window.location.href = "/knowledge_suite/appointments/new";
  };

  const getAppointments = () => {
    const meetingID = 1;
    axios.get(`/appointments/${meetingID}`).then(response => null);
  };

  // Setup the localizer by providing the moment (or globalize) Object
  // to the correct localizer.
  //const localizer = BigCalendar.momentLocalizer(moment); // or globalizeLocalizer

  return <FullCalendar defaultView="dayGridMonth" plugins={[dayGridPlugin]} />;
};

export default Calendar;
