import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  Divider,
  Fab,
  RadioGroup,
  Radio,
  FormControlLabel,
  Avatar,
  CardContent,
  InputBase,
  FormControl,
  InputLabel,
  Select,
  MenuItem, Card
} from "@material-ui/core";
import { Event, PersonAdd } from "@material-ui/icons";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


import pic from "./profile_pic.png";
import pic2 from "./face4.png";


const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    //width: "859px"
  },
  sidebar: {
    flex: "0",
    padding: "0px",
    backgroundColor: "#E5870C"
  },
  calendar: {
    height: "53px",
    width: "50px",
    color: "white",
    margin: "16px"
  },
  card: {
    flex: 1,
  },
  spacing: {
    margin: `${theme.spacing(1)}px 0`,
    fontWeight: "500px"
  },
  avatar: {
    width: "64px",
    height: "64px"
  },
  avatars: {
    display: "flex",
    flexDirection: "row"
  },
  item_center: {
    alignItems: "center"
  },
  avatarDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    margin: "18px"
  },
  divider_vert: {
    margin: "20px",
    height: "117px",
    border: "1px #ACB6B2"
  },
  text: {
    fontWeight: "bolder"
  },
  dolar_textfield: {
    margin: `${theme.spacing(0.5)}px`,
    width: "65px",
    marginLeft: "-10px"
  },
  minute_textfield: {
    margin: `${theme.spacing(0.5)}px`,

    minWidth: "87px"
  },
  radio_div: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row"
  },
  btn_spacing: {
    marginRight: "50px"
  },
  radio_margin: {
    marginLeft: "40px"
  },
  dialog_textbox: {
    width: "468px",
    height: "96px"
  },
}));
const meeting_type = {
  clientRequestMeeting: "clientRequestMeeting",
  providerReceiveRequest: "providerReceiveRequest",
  providerSendInvite: "providerSendInvite"
};
const headings = {
  [meeting_type.clientRequestMeeting]: {
    heading: "Request a Meeting with",
    heading1: "Provider",
    heading2: "Attendees"
  },
  [meeting_type.providerReceiveRequest]: {
    heading: "You have Recieved a Meeting Request",
    heading1: "Requester",
    heading2: "Additional Attendees"
  },
  [meeting_type.providerSendInvite]: {
    heading: "Invite Clients to a Meeting",
    heading1: "Provider",
    heading2: "Attendees"
  }
};
function RequestMeeting(props) {
  const classes = useStyles();
  const MeetReq = props.meeting_type;
  const { meeting_type } = props;
  const [open, setOpen] = React.useState(false);
  const mainheading = { ...headings[meeting_type] };
  const [user, setUser] = useState({
    ...props.user,
    provider_name: "Jane doe",
    date: " Thursday October 13th",
    time: "4:00 PM - 5:00 PM",
    selected_time: "1",
    rate: 75,
    session_rate: 75,
    minute: "minute",
    total_rate: "",
    meeting_topic:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna"
  });
  const [attendee, setAttendee] = useState({
    attendee_name: ["You ", "Jennifer burns"],
    attendee_pic: ["", ""]
  });
  const [value, setValue] = React.useState("75 minute");
  const handleClick = input => e => {
    e.preventDefault();

    switch (input) {
      case 'send':
        return e.preventDefault();;
      case 'discard':
        return;
      case 'accept':
        return alert("availability");
      case 'reject':
        return setOpen(true);
      case 'dialogBack':
        return setOpen(false);
      case 'dialogReject':
        return;

      default:
        return "hii";
    }

  }
  const handleChange1 = event => {
    setValue(event.target.value);

  };
  const handleChange = input => e => {
    setUser({ ...user, [input]: e.target.value });
  };



  return (
    <Card className={classes.container}>
      <div className={classes.sidebar}>
        <Event className={classes.calendar} />
      </div>
      <div >

        <CardContent >
          <Typography variant="h5" component="h5">
            {mainheading.heading}
            {MeetReq === "clientRequestMeeting" && (
              <span> {user.provider_name}</span>
            )}
          </Typography>
          <br />
          <Grid container spacing={1}>
            <Grid item >

              <Typography variant="h6" className={classes.text}>
                {mainheading.heading1}
              </Typography>
              <div className={classes.avatarDiv}>
                <Avatar
                  alt="Profile Pic"
                  src={pic}
                  className={classes.avatar}
                />
                <Typography component="caption">
                  {" "}
                  {user.provider_name}
                </Typography>
              </div>

            </Grid>
            <Divider orientation="vertical" className={classes.divider_vert} />
            <Grid item >
              <Typography variant="h6" className={classes.text}>
                {mainheading.heading2}
              </Typography>
              <Box className={classes.avatars}>
                {attendee.attendee_name.map((name, index) => (
                  <div className={classes.avatarDiv}>
                    <Avatar
                      alt="Profile Pic"
                      src={pic2}
                      key={index}
                      className={classes.avatar}
                    />

                    <Typography component="caption" key={index}>
                      {name}
                    </Typography>
                  </div>
                ))}
                {(MeetReq === "clientRequestMeeting" ||
                  MeetReq === "providerSendInvite") && (
                    <div className={classes.avatarDiv}>
                      <Fab color="primary" className={classes.avatar}>
                        <PersonAdd />
                      </Fab>
                      <Typography>Add</Typography>
                    </div>
                  )}
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item>
              <Typography variant="h6" component="h2">
                Date and time:
            </Typography>
              <Box className={classes.box}>
                <Typography color="primary">{user.date}</Typography>
                <Typography color="primary">{user.time}</Typography>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" className={classes.text}>
                Rate:
            </Typography>
              <div className={classes.radio_div}>
                {MeetReq === "clientRequestMeeting" && (
                  <RadioGroup value="1 hour" onChange={handleChange}>
                    <FormControlLabel
                      value="1 hour"
                      control={<Radio color="primary" />}
                      label={`${user.selected_time} hour @${
                        user.rate
                        }/hour = $${user.selected_time * user.rate} total`}
                    />
                  </RadioGroup>
                )}

                {MeetReq === "providerSendInvite" && (
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <RadioGroup
                      className={classes.radio_div}

                      value={value}
                      onChange={handleChange1}
                    >
                      <FormControlLabel
                        value={`${user.rate} minute`}
                        control={<Radio color="primary" />}
                      />
                      <TextField
                        label="$"
                        variant="filled"
                        color="primary"
                        className={classes.dolar_textfield}
                        value={user.rate}
                        onChange={handleChange("rate")}
                      />
                      <FormControl
                        variant="filled"
                        color="primary"
                        className={classes.minute_textfield}
                      >
                        <InputLabel
                          htmlFor="filled-age-simple"
                          color="primary"
                        ></InputLabel>
                        <Select value={user.minute} onChange={handleChange("minute")}>
                          <MenuItem value="minute">minute</MenuItem>
                          <MenuItem value="hour">hour</MenuItem>
                          <MenuItem value="day">day</MenuItem>
                        </Select>
                      </FormControl>

                      <FormControlLabel
                        value="Session"
                        control={
                          <Radio
                            color="primary"
                            className={classes.radio_margin}
                          />
                        }
                        label="Session"
                      ></FormControlLabel>
                      <TextField
                        label="$"
                        variant="filled"
                        color="primary"
                        className={classes.dolar_textfield}
                        value={user.session_rate}
                        onChange={handleChange("session_rate")}
                      />



                      <FormControlLabel
                        value="Free call"
                        control={
                          <Radio
                            color="primary"
                            className={classes.radio_margin}
                          />
                        }
                        label="Free call"
                      />
                    </RadioGroup>
                  </FormControl>
                )}
              </div>

              {MeetReq === "providerReceiveRequest" && (
                <Typography>
                  {user.selected_time} hour @${user.rate} / hour = $
                {user.selected_time * user.rate} total
              </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.text}>
                Meeting Topic
            </Typography>

              {(MeetReq === "clientRequestMeeting" ||
                MeetReq === "providerSendInvite") && (
                  <TextField
                    variant="filled"
                    required
                    fullWidth
                    multiline
                    margin="normal"

                    value={user.meeting_topic}
                    onChange={handleChange("meeting_topic")}
                  />
                )}
              {MeetReq === "providerReceiveRequest" && (
                <InputBase
                  margin="normal"
                  required
                  fullWidth
                  multiline

                  value={user.meeting_topic}
                  onChange={handleChange("meeting_topic")}
                />
              )}
            </Grid>
          </Grid>
        </CardContent>


        <Divider />

        <CardContent>
          <Grid container>

            {(MeetReq === "clientRequestMeeting" ||
              MeetReq === "providerSendInvite") && (
                <Grid item>
                  <Button
                    className={classes.btn_spacing}
                    component="button"
                    color="primary"
                    onClick={handleClick("send")}
                  >
                    SEND
              </Button>
                  <Button
                    className={classes.btn_spacing}
                    component="button"
                    color="primary"
                    onClick={handleClick("discard")}
                  >
                    DISCARD
              </Button>
                </Grid>
              )}

            {MeetReq === "providerReceiveRequest" && (
              <Grid item>
                <Button
                  className={classes.btn_spacing}
                  component="button"
                  color="primary"
                  onClick={handleClick("accept")}
                >
                  ACCEPT
              </Button>
                <Button
                  className={classes.btn_spacing}
                  component="button"
                  color="primary"
                  onClick={handleClick("reject")}
                >
                  REJECT
              </Button>

                <Dialog open={open} onClose={handleClick("dialogBack")} aria-labelledby="form-dialog-title">
                  <DialogTitle id="form-dialog-title">Reject this Meeting?</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      <TextField
                        className={classes.dialog_textbox}
                        variant="filled"
                        autoFocus


                        id="name"
                        label="Enter a message for rejecting this meeting(optional)"
                        type="email"
                        fullWidth
                        color="primary"
                      />
                    </DialogContentText>

                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClick("dialogBack")} className={classes.btn_spacing} color="primary">
                      Back
          </Button>
                    <Button onClick={handleClick("dialogReject")} color="primary">
                      Reject
          </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            )}

          </Grid>
        </CardContent>

      </div>
    </Card>
  );
}
export { RequestMeeting as default, meeting_type };
