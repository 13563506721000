import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_API_URL || "http://localhost:8080";

const fetch = axios.create({
  baseURL: `${baseUrl}`
});

fetch.defaults.headers.common["Content-Type"] = "application/json";
fetch.defaults.headers.common["Accept"] = "application/json";

if (localStorage.getItem("AuthorizationToken") !== null) {
  fetch.defaults.headers.common["Authorization"] = `${localStorage.getItem(
    "AuthorizationToken"
  )}`;
}

export default fetch;
