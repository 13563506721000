import React, { useState } from "react";
import {
  Box,
  Typography,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import pic from "../profile_pic.png";
const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start"
  },
  bubbleContainer: {
    display: "flex",
    alignItems: "center",
    margin: "30px"
  },

  talkBubbleLeft: {
    background: "#EAEAEA",
    padding: "10px",
    fontWeight: "400",
    fontFamily: "Roboto",
    color: "#000",
    position: "relative",
    borderRadius: "0 5px 5px 5px",
    "&:before": {
      content: '""',
      width: "0px",
      height: "0px",
      position: "absolute",
      borderLeft: "10px solid transparent",
      borderRight: "10px solid #EAEAEA",
      borderTop: "10px solid #EAEAEA",
      borderBottom: "10px solid transparent",
      left: "-19px",
      top: "0px"
    }
  },
  talkBubbleRight: {
    background: "#DEF1EF",
    padding: "10px",
    fontWeight: "400",
    fontFamily: "Roboto",
    color: "#000",
    position: "relative",
    borderRadius: "0 5px 5px 5px",
    "&:before": {
      content: '""',
      width: "0px",
      height: "0px",
      position: "absolute",
      borderRight: "12px solid transparent",
      borderLeft: "10px solid #DEF1EF",
      borderTop: "11px solid #DEF1EF",
      borderBottom: "10px solid transparent",
      right: "-19px",
      top: "0px"
    }
  },

  avatar: {
    width: 60,
    height: 60
  },
  timeStamp: {
    display: "flex",
    justifyContent: "center",
    color: "#ACB6B2",
    fontSize: "11px"
  }
}));

export default function MessageList(props) {
  const [user, setUser] = useState(props.user);
  const { msg } = props;
  const classes = useStyles();
  return (
    <Box>
      <List className={classes.root}>
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar alt="Remy Sharp" src={pic} />
          </ListItemAvatar>
          <ListItemText
            primary={user.first_name}
            secondary={
              <React.Fragment>{" October 24th, 2019 -8:13PM"}</React.Fragment>
            }
          />
        </ListItem>
      </List>
      <Box className={classes.bubbleContainer}>
        <Box className={classes.talkBubbleLeft}>{msg}</Box>
      </Box>

      <List className={classes.root}>
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar alt="Remy Sharp" src={pic} />
          </ListItemAvatar>
          <ListItemText
            primary={user.user2}
            secondary={
              <React.Fragment>{" October 24th, 2019 -8:13PM"}</React.Fragment>
            }
          />
        </ListItem>
      </List>
      <Box className={classes.bubbleContainer}>
        <Box className={classes.talkBubbleRight}>{msg}</Box>
      </Box>
    </Box>
  );
}
