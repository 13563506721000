import React from "react";
import user from "../models/user";
import ChatPage from "../components/Messaging/ChatPage";

const Messages = props => {
  return (
    <ChatPage user={user} />
  );
};

Messages.propTypes = {};

export default Messages;
