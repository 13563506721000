import React from "react";
import RequestMeeting, { meeting_type } from "../components/RequestMeeting";
import user from "../models/user";

const ProviderReceiveRequest = props => {
    return <RequestMeeting user={user} meeting_type={meeting_type.providerReceiveRequest} />;
};

ProviderReceiveRequest.propTypes = {};

export default ProviderReceiveRequest;
