import { STORE_CONVERSATIONS, STORE_CONVERSATION } from "../actions";

const initialState = {
  conversations: [],
  conversationsLoaded: false,
  conversationLoaded: false
};

function conversations(state = initialState, action) {
  switch (action.type) {
    case STORE_CONVERSATIONS: {
      return {
        ...state,
        conversations: [...action.payload.conversations],
        conversationsLoaded: true
      };
    }

    case STORE_CONVERSATION: {
      return {
        ...state,
        conversation: { ...action.payload.conversation },
        conversationLoaded: true
      };
    }
    default:
      return state;
  }
}

export default conversations;
