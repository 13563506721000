import React from "react";
import { Grid, IconButton, Button } from "@material-ui/core";
import {
    LinkedIn,
    Facebook,
    YouTube,
    Twitter,
    Instagram
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";


const useStyles = makeStyles(theme => ({
    margin: {
        margin: `0 ${theme.spacing(0.5)}px`
    }
}));
const handleSocialLinkClick = input => e => {
    e.preventDefault();

    switch (input) {
        case 'twitter':
            return alert("twitter");
        case 'linked':
            return alert("linked");
        case 'facebook':
            return alert("facebook");
        case 'youtube':
            return alert("youtube");
        case 'instagram':
            return alert("instagram");
        default:
            return "hii";

    }

}


function SocialLinks(props) {
    const classes = useStyles();
    const { user } = props;
    return (

        <div >
            <Grid container spacing={1}>
                <Grid item>

                    <div>

                    </div>
                    {user.linked_in_account_name && (
                        <LinkedIn color="primary" className={classes.margin} onClick={handleSocialLinkClick("linked")} />

                    )}

                    {user.facebook_username && (
                        <Facebook color="primary" className={classes.margin} onClick={handleSocialLinkClick("facebook")} />
                    )}


                    {user.twitter_handle && (
                        <Twitter color="primary" className={classes.margin} onClick={handleSocialLinkClick("twitter")} />
                    )}


                    {user.instagram_handle && (
                        <Instagram color="primary" className={classes.margin} onClick={handleSocialLinkClick("instagram")} />
                    )}


                    {user.youtube_channel_name && (
                        <YouTube color="primary" className={classes.margin} onClick={handleSocialLinkClick("youtube")} />
                    )}

                </Grid>

            </Grid>
        </div>

    );
}


export { SocialLinks as default };
