import axios from "../helpers/fetch";
import actionTyper from "redux-actiontyper";
import history from "../helpers/history";
import { navigate } from "@reach/router";

export const {
  UPDATE_USER,
  USER_CREATED,
  USER_SUCCESSFULLY_LOGGED_IN,
  USER_SUCCESSFULLY_LOGGED_OUT,
  IS_LOGGING_IN,
  LOGOUT,
  USER_RESENT_VARIFICATION_EMAIL
} = actionTyper();

export const updateUser = () => dispatch => {
  axios.get("/api/agents").then(user => {
    dispatch({
      type: UPDATE_USER,
      payload: { user }
    });
  });
};

export const createUser = user => dispatch => {
  axios.post("/users", user).then(user => {
    dispatch({
      type: USER_CREATED,
      payload: { user }
    });
  });
};

export const loginUser = ({ username, password, originalPath }) => dispatch => {
  const url = "/users/sign_in.json";

  var bodyFormData = new FormData();
  bodyFormData.set("user[login]", username);
  bodyFormData.set("user[password]", password);

  axios({
    method: "post",
    url: url,
    data: bodyFormData,
    config: { headers: { "Content-Type": "multipart/form-data" } }
  })
    .then(({ data: user, headers }) => {
      localStorage.setItem("AuthorizationToken", headers["authorization"]);
      localStorage.setItem("UserName", user.username);

      dispatch({
        type: USER_SUCCESSFULLY_LOGGED_IN,
        payload: { user }
      });

      navigate(originalPath || "/dashboard");
    })
    .catch(err => {
      if (err.response) {
        if (err.response.status === 401) {
          //setState({ failedPass: true });
        }
      }
    });
};

export const logoutUser = () => dispatch => {
  dispatch({
    type: USER_SUCCESSFULLY_LOGGED_OUT
  });

  history.push("/login");
};

export const initialize = url => dispatch => {
  dispatch({ type: IS_LOGGING_IN });
  const username = localStorage.getItem("UserName");
  const fetchUserInfo = axios
    .get(`/users/${username}`)
    .then(({ data: user }) => user || {})
    .catch(err => {
      throw err;
    });
  Promise.all([fetchUserInfo])
    .then(([user]) => {
      dispatch({
        type: USER_SUCCESSFULLY_LOGGED_IN,
        payload: { user }
      });

      console.log(url);
      navigate(url || "/");
    })
    .catch(err => {
      console.log(err);
    });
};

export const logout = () => dispatch => {
  localStorage.clear();
  dispatch({ type: LOGOUT });
};

// story 1.3
export const resendEmail = () => dispatch => {
  axios.post("/users/resend_email_verification").then(() => {
    dispatch({
      type: USER_RESENT_VARIFICATION_EMAIL
    });
  });
};
