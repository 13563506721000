import React from "react";
import {
  makeStyles,
  CardContent,
  Typography,
  Grid,
  Box,
  Paper,
  Fab,
  Avatar
} from "@material-ui/core";
import Sticky from "./shared/Sticky";
import initialUser from "../models/user";
import { navigate } from "@reach/router";
import Rating from "@material-ui/lab/Rating";

import {
  PhoneRounded,
  ChatBubbleOutlineRounded,
  CalendarTodayRounded
} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  color: {
    color: theme.palette.primary.main
  },
  container: {
    display: "flex",
    position: "relative"
  },
  avatar: {
    marginTop: 10,
    width: 167,
    height: 167
  },
  video_btn: {
    marginLeft: 5,
    border: "none"
  },
  star_li: {
    color: theme.palette.primary.main,
    listStyle: "none"
  },
  spacing: {
    margin: `${theme.spacing(2)}px 0`
  },
  icon_div: {
    margin: `0 ${theme.spacing(0.5)}px`,
    display: "flex"
  },
  align_div: {
    display: "inline-flex",
    alignItems: "center"
  },
  rounded_div: {
    margin: `${theme.spacing(2)}px 0`
  },
  icons: {
    margin: "1px",
    display: "flex"
  },
  specs: {
    margin: `0 ${theme.spacing(0.5)}px`
  },
  video_div: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  },
  text1: {
    width: "893px",
    height: "2"
  },
  text2: {
    width: "893px",
    height: "212.43px",
    fontSize: "14px"
  },
  rate_text: {
    top: "13px",
    fontSize: "24px"
  },
  textsmall: {
    top: "42px",
    fontSize: "11px"
  },
  pos_li: {
    display: "flex",
    flexDirection: "row"
  },
  pos_li1: {
    fontSize: "16px",
    marginLeft: "10px",
    marginTop: "4px"
  },
  call_div: {
    display: "flex",
    alignItems: "center"
  },
  divider_vert: {
    margin: "20px",
    height: "20px",
    border: "1px  #ACB6B2"
  },
  avatar_small: {
    margin: 10,
    width: 64,
    height: 64
  },
  grid_margin: {
    marginTop: "-30px"
  }
}));

const ProfileCompact = props => {
  const classes = useStyles();
  const user = { ...initialUser, ...props.user };

  const onOpenProfileClick = () => {
    navigate(`providers/${user.username}`);
  };

  return (
    <Paper className={classes.container} onClick={onOpenProfileClick}>
      <Sticky rate={user.video_call_rate} />

      <Grid container>
        <Grid item>
          <Avatar
            alt="Profile Pic"
            src={user.thumbnail_url}
            className={classes.avatar_small}
          />
        </Grid>
        <Grid item>
          <Typography variant="h4">
            {user.first_name} {user.last_name}
          </Typography>

          <Box className={classes.pos_li}>
            <Typography variant="subtitle1">Position Here</Typography>
            {/* {user.position.slice(1).map(position => (
              <li
                variant="subtitle1"
                key={position}
                className={classes.pos_li1}
              >
                {position}
              </li>
            ))} */}
          </Box>

          <Typography color="textSecondary">{user.city_name}</Typography>
          <div className={classes.align_div}>
            <Rating value={1} readOnly className={classes.color} />
            <span className={classes.color}>{55}</span>
          </div>
        </Grid>

        <Grid container>
          <Grid item className={classes.cardcontent}>
            <CardContent className={classes.cardcontent}>
              <Grid item xs={12} className={classes.spacing}>
                {/* {user.keywords.map((specs, index) => (
                  <Chip
                    className={(classes.chip, classes.specs)}
                    key={user.id}
                    label={specs}
                    color="secondary"
                    variant="outlined"
                  />
                ))} */}
              </Grid>

              <Grid
                container
                spacing={2}
                className={(classes.spacing, classes.icons)}
              >
                <Grid item xs={12}>
                  <Fab color="primary" size="small">
                    <PhoneRounded />
                  </Fab>
                  <Fab color="primary" size="small">
                    <ChatBubbleOutlineRounded />
                  </Fab>
                  <Fab color="primary" size="small">
                    <CalendarTodayRounded />
                  </Fab>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Typography className={classes.bio}>
                  {user.short_bio}
                </Typography>
              </Grid>
            </CardContent>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ProfileCompact;
