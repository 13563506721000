import React from "react";
import Login from "../components/Login";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  main: {
    margin: "0 auto",
    maxWidth: 700
  }
}));

const LoginPage = props => {
  const classes = useStyles();
  return (
    <>
      <Helmet title="Magnifi - Login" />
      <div className={classes.main}>
        <Login {...props} />
      </div>
    </>
  );
};

export default LoginPage;
