import React from "react";
import { default as ForgotPasswordForm } from "../components/ForgotPassword";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  main: {
    margin: "0 auto",
    maxWidth: 700
  }
}));

const ForgotPassword = props => {
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <ForgotPasswordForm {...props} />
    </div>
  );
};

export default ForgotPassword;
