const provider = {
  uuid: "bestuuidna8",
  username: "hank_harris",
  first_name: "Jane",
  last_name: "Doe",
  last_seen: null,
  days_visited: 0,
  days_consecutive_visited: 0,
  primary_video_thumbnail_url: null,
  primary_video_image_url: null,
  primary_video_url: null,
  thumbnail_url:
    "https://s3.amazonaws.com/magnifi-development/mindshare/images/3asw9NJ31ZiCNsIZQ8qG8yPDg_CHiPZX/00ec344fe3a5058f7fa784882d0d0fb9090429c3.jpg?1570734514",
  medium_image_url:
    "https://s3.amazonaws.com/magnifi-development/mindshare/images/3asw9NJ31ZiCNsIZQ8qG8yPDg_CHiPZX/89c07d74b4a259416fd88ee906367f5e288ce6d7.jpg?1570734514",
  full_image_url:
    "https://s3.amazonaws.com/magnifi-development/mindshare/images/3asw9NJ31ZiCNsIZQ8qG8yPDg_CHiPZX/9cc4ba005ce9f232dbbc79c19e33d77361a314cc.jpg?1570734514",
  monday_start_time: "1968-12-30T09:00:00.000Z",
  monday_number_of_minutes: 480,
  tuesday_start_time: "1968-12-31T09:00:00.000Z",
  tuesday_number_of_minutes: 480,
  wednesday_start_time: "1969-01-01T09:00:00.000Z",
  wednesday_number_of_minutes: 480,
  thursday_start_time: "1969-01-02T09:00:00.000Z",
  thursday_number_of_minutes: 480,
  friday_start_time: "1969-01-03T09:00:00.000Z",
  friday_number_of_minutes: 480,
  saturday_start_time: "1969-01-04T09:00:00.000Z",
  saturday_number_of_minutes: 480,
  share_link: null,
  sunday_start_time: "1969-01-05T09:00:00.000Z",
  sunday_number_of_minutes: 480,
  default_language: "en",
  call_free_time: 5,
  is_featured: false,
  hide_location: false,
  video_call_rate: 25,
  ratings_count: 0,
  rating_score_out_of_5: null,
  has_free_calls: null,
  bio: null,
  title: null,
  follower_count: 0,
  following_count: 0,
  can_call_now: false,
  full_name: "Hank Harris",
  added_email: "2019-10-10T19:08:28.627Z",
  latitude: null,
  longitude: null,
  city_name: null,
  city_country_code: null,
  primary_category: {
    uuid: "rmicyZlsdu7ixNSU685nEc7gn2TvrhpD",
    full_image_url:
      "https://s3.amazonaws.com/magnifi-development/mindshare/categories/c21aaa45d76bde841bc967b606b73d9f0f082f3e.jpg?1570734451",
    thumbnail_url:
      "https://s3.amazonaws.com/magnifi-development/mindshare/categories/a1384b0cc0ecba31742c70ab8abe0ecb4f097d5b.jpg?1570734451",
    name: "Food",
    description: "This is the description."
  },
  secondary_category: null,
  tertiary_category: null
};

export default provider;
