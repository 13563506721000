import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import validate from "../helpers/validateUser";
import {
  TextField,
  Button,
  Typography,
  Link,
  Box,
  Paper
} from "@material-ui/core";
import { createUser } from "../actions";

const useStyles = makeStyles(theme => ({
  container: {
    padding: "25px 30px"
  },
  card: {
    flexGrow: 1
  },
  colBlack: {
    color: "black"
  },
  linksDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "8px"
  },
  margin: {
    margin: `${theme.spacing(0.5)}px`
  },
  buttonDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    margin: "25px 30px"
  },
  buttonStyle: {
    width: "220px",
    hieght: "36px"
  },
  textField: {
    margin: theme.spacing(2, 0)
  }
}));

function Register(props) {
  const classes = useStyles();
  const { createUser, navigate } = props;
  const [errors, setErrors] = React.useState([]);
  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    password_confirmation: "",
    password: ""
  });

  const handleChange = input => e => {
    setUser({ ...user, [input]: e.target.value });
  };

  const handleSubmit = e => {
    e.preventDefault();

    validate(user)
      .then(val => {
        const entity = {
          user: {
            email: user.email,
            full_name: `${user.first_name} ${user.last_name}`,
            password: user.password,
            password_confirmation: user.password,
            username: user.username
          }
        };
        createUser(entity);
      })
      .catch(err => {
        const errorMap = err.inner.map(inner => inner.path);
        setErrors(errorMap);
      });
  };

  const preventDefault = e => e.preventDefault();

  return (
    <Paper className={classes.container}>
      <TextField
        className={classes.textField}
        variant="filled"
        label="First Name"
        required
        fullWidth
        autoFocus
        value={user.first_name}
        onChange={handleChange("first_name")}
        helperText={
          errors.includes("first_name") && "First name requires 3 characters"
        }
        error={errors.includes("first_name")}
      />
      <TextField
        className={classes.textField}
        variant="filled"
        label="Last Name"
        required
        fullWidth
        value={user.last_name}
        onChange={handleChange("last_name")}
        helperText={
          errors.includes("last_name") && "Last name requires 3 characters"
        }
        error={errors.includes("last_name")}
      />

      <TextField
        className={classes.textField}
        variant="filled"
        label="User Name"
        required
        fullWidth
        value={user.username}
        onChange={handleChange("username")}
        helperText={
          errors.includes("_name") && "First name requires 3 characters"
        }
        error={errors.includes("first_name")}
      />

      <TextField
        className={classes.textField}
        label="Email"
        variant="filled"
        required
        fullWidth
        value={user.email}
        onChange={handleChange("email")}
        helperText={
          errors.includes("first_name") && "First name requires 3 characters"
        }
        error={errors.includes("first_name")}
      />

      <TextField
        className={classes.textField}
        label="password"
        type="password"
        variant="filled"
        fullWidth
        required
        value={user.password}
        onChange={handleChange("password")}
        helperText={
          errors.includes("first_name") && "First name requires 3 characters"
        }
        error={errors.includes("first_name")}
      />

      <Box className={classes.linksDiv}>
        <Typography>By clicking join you agree to the </Typography>
        <Link href="#" className={classes.margin} onClick={preventDefault}>
          Magnifi User Agreement,
        </Link>
        <Link href="#" className={classes.margin} onClick={preventDefault}>
          Privacy Policy,
        </Link>
        and
        <Link href="#" className={classes.margin} onClick={preventDefault}>
          Cookie Policy.
        </Link>
      </Box>

      <Box className={classes.buttonDiv}>
        <Button
          variant="contained"
          color="primary"
          className={classes.buttonStyle}
          onClick={handleSubmit}
        >
          Agree & Join
        </Button>
        <Box className={classes.linksDiv}>
          <Typography>Already a Magnifi Member?</Typography>
          <Link
            href="#"
            className={classes.margin}
            onClick={e => {
              e.preventDefault();
              navigate("/login");
            }}
          >
            Sign In Now
          </Link>
        </Box>
      </Box>
    </Paper>
  );
}

export default connect(
  null,
  {
    createUser
  }
)(Register);
