import { STORE_PROVIDERS, SEARCH_PROVIDERS } from "../actions";
import provider from "../models/provider";

const initialState = {
  providers: [provider],
  providersLoaded: false,
  searchText: ""
};

function providers(state = initialState, action) {
  switch (action.type) {
    case STORE_PROVIDERS: {
      return {
        ...state,
        providers: [...state.providers, ...action.payload.providers],
        providersLoaded: true
      };
    }
    case SEARCH_PROVIDERS: {
      return {
        ...state,
        searchText: action.payload.text
      };
    }
    default:
      return state;
  }
}

export default providers;
