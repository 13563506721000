import React, { useState, useEffect } from "react";
import Profile from "../components/Profile";
import { useProvidersByUsername } from "../hooks/providers";

const Provider = props => {
  useEffect(() => {
    console.log(props.id);
  }, [props.id]);

  const provider = useProvidersByUsername(props.id);

  return <Profile user={provider} isProvider />;
};

export default Provider;
