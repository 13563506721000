import React from "react";
import { Grid, Tooltip, Button } from "@material-ui/core";
import {
  PhoneRounded,
  ChatBubble,
  CalendarTodayRounded
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";
import history from "../../helpers/history";
import CallNowPopup from "./CallNowPopup";

const handleClick = input => e => {
  e.preventDefault();
  switch (input) {
    case "call_now":
      return;
    case "send_message":
      history.push("/messages");
    case "availability":
      history.push("/meetings");
    default:
      return "";
  }
};

function CallMsg(props) {
  return (
    <Grid container>
      <Grid item>
        <Tooltip title={<CallNowPopup {...props} />}>
          <Button
            color="primary"
            startIcon={<PhoneRounded />}
            onClick={handleClick("call_now")}
          >
            CALL NOW
          </Button>
        </Tooltip>

        <Button
          color="primary"
          startIcon={<ChatBubble />}
          onClick={handleClick("send_message")}
        >
          SEND MESSAGE
        </Button>
        <Button
          color="primary"
          startIcon={<CalendarTodayRounded />}
          onClick={handleClick("availability")}
        >
          AVAILABILITY
        </Button>
      </Grid>
    </Grid>
  );
}

export { CallMsg as default };
