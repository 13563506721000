import { STORE_NOTIFICATIONS } from "../actions";

const initialState = {
  notifications: [],
  notificationsLoaded: false
};

function notifications(state = initialState, action) {
  switch (action.type) {
    case STORE_NOTIFICATIONS: {
      return {
        ...state,
        notifications: [...action.payload.notifications],
        notificationsLoaded: true
      };
    }
    default:
      return state;
  }
}

export default notifications;
