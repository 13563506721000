import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        backgroundColor: "none"
      }

    },
    // MuiFilledInput: {
    //   input: {
    //     padding: '10px 12px 10px'
    //   }
    // }
  },
  palette: {
    primary: {
      light: "#addcd7",
      main: "#3caea3",
      dark: "#388E86",
      contrastText: "#fff"
    },
    secondary: {
      light: "#f3c880",
      main: "#E5870C",
      dark: "#CE5204",
      contrastText: "#fff"
    },
    tertiary: {
      light: "#bae8f6",
      main: "#3C80AE",
      dark: "#00547E",
      contrastText: "#fff"
    }
  }
});

export default theme;
