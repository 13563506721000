import React, { useEffect } from "react";
import { navigate } from "@reach/router";
import { useSelector } from "react-redux";

const CustomRedirectToLogin = props => {
  useEffect(() => {
    navigate("/login", { state: { originalPath: props.uri } });
  }, [props.uri]);
  return null;
};

const Route = props => {
  const { isPrivate, component: Component, uri, ...renderProps } = props;
  const { isLoggedIn, isLoggingIn } = useSelector(state => state.user);
  if (!isPrivate) return <Component {...renderProps} />;
  if (!isLoggedIn && !isLoggingIn) return <CustomRedirectToLogin uri={uri} />;

  return <Component {...renderProps} />;
};

const RouteGroup = props => props.children;

export { Route, RouteGroup };
