import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { loginUser } from "../actions/user";

import {
  Card,
  Link,
  Box,
  TextField,
  Grid,
  Typography,
  Button,
  InputAdornment,
  IconButton,
  FormHelperText,
  Container
} from "@material-ui/core";
import { VisibilityOff, Visibility, VpnKey } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  card: {
    padding: theme.spacing(2)
  },
  logoWrapper: {
    margin: "2rem auto"
  },
  name: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontSize: 17,
    paddingTop: "1rem",
    color: "#323335"
  },
  company: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    paddingTop: "0.5rem",
    color: "#323335",
    letterSpacing: 0.8,
    fontSize: 12
  },
  vpnKey: {
    color: "#B9B9B9",
    width: 20,
    height: 20
  },
  inputRoot: {
    margin: theme.spacing(1)
  },
  visibility: {
    color: theme.palette.primary.main
  },
  loginButton: {
    color: theme.palette.common.white,
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    fontWeight: 100,
    width: "80%"
  },
  royallepage: {
    margin: "3rem 0"
  },
  errorBold: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 12,
    fontWeight: 500,
    color: "#323335"
  },
  error: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 12,
    color: "#323335"
  },
  incorrectPass: {
    display: "block",
    width: "80%",
    paddingBottom: "1rem"
  },
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  buttonStyle: {
    width: "220px",
    hieght: "36px",
    marginBottom: 10
  }
}));

const Login = props => {
  const classes = useStyles();
  const { loginUser, location, navigate } = props;
  const originalPath = location.state ? location.state.originalPath : "/";

  const [state, setState] = useState({
    showPassword: false,
    failedPass: false,
    password: "",
    username: ""
  });

  const { username, password } = state;

  const handleSubmit = e => {
    e.preventDefault();
    loginUser({ username, password, originalPath });
  };

  const handleClickShowPassword = () => {
    setState({ ...state, showPassword: !state.showPassword });
  };

  const handleChange = prop => e => {
    setState({ ...state, [prop]: e.target.value });
  };

  return (
    <Container>
      <Card className={classes.card}>
        <Grid
          className={classes.grid}
          container
          direction="column"
          alignItems="center"
          justify="center"
        >
          <Container>
            <TextField
              label="Email"
              id=""
              value={state.username}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              onChange={handleChange("username")}
              variant="filled"
              fullWidth
            />
            <TextField
              label="Password"
              id="adornment-password"
              type={state.showPassword ? "text" : "password"}
              value={state.password}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              onChange={handleChange("password")}
              variant="filled"
              startAdornment={<VpnKey className={classes.vpnKey} />}
              // disableUnderline
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    {state.showPassword ? (
                      <Visibility className={classes.visibility} />
                    ) : (
                        <VisibilityOff className={classes.visibility} />
                      )}
                  </IconButton>
                </InputAdornment>
              }
              fullWidth
            />
            {/* <FormHelperText className={classes.incorrectPass}>
              {state.failedPass && (
                <>
                  <Typography className={classes.errorBold}>
                    The password you entered is incorrect.
                  </Typography>
                  <Typography className={classes.error}>
                    Please contact your agent for help.
                  </Typography>
                </>
              )}
            </FormHelperText> */}
          </Container>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={classes.buttonStyle}
            onClick={handleSubmit}
          >
            SIGN IN
          </Button>
          <Link
            href="#"
            className={classes.margin}
            onClick={e => {
              e.preventDefault();
              navigate("/forgot-password");
            }}
          >
            {" "}
            Forgot Password ?
          </Link>
          <Box className={classes.linksDiv}>
            <Typography>
              Not a member yet?
              <Link
                href="#"
                className={classes.margin}
                onClick={e => {
                  e.preventDefault();
                  navigate("/register");
                }}
              >
                {" "}
                Sign up Now.
              </Link>
            </Typography>
          </Box>
        </Grid>
      </Card>
    </Container>
  );
};

export default connect(
  null,
  {
    loginUser
  }
)(Login);
