import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  InputLabel,
  Button,
  Typography,
  Paper,
  Grid,
  FormControl,
  Divider,
  Fab,
  MenuItem,
  Select
} from "@material-ui/core";
import { Facebook, Publish, YouTube, CameraAlt } from "@material-ui/icons";
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles(theme => ({
  container: {
    padding: "25px 30px",
    flexGrow: 1
  },
  margin: {
    marginRight: "50px"
  },
  buttonDiv: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row",
    marginTop: "25px"
  },
  buttonStyle: {
    width: "238px",
    hieght: "36px"
  },
  uploadDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  },
  cameraDiv: {
    width: "150px",
    height: "150px",
    marginBottom: "23px"
  },
  cameraIcon: {
    width: "57.5px",
    height: "51px",
    color: "#ACB6B2"
  },
  rateDiv: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    marginLeft: "-6px"
  },
  dollarTextfield: {
    margin: theme.spacing(1),
    width: "108px",
    hieght: "40px"
  },
  minuteTextfield: {
    margin: theme.spacing(1),
    width: "152px",
    hieght: "40px"
  },
  labelDiv: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    marginTop: "15px",
    marginBottom: "2px"
  },
  dividerVertical: {
    width: "300px",
    border: "1px  #ACB6B2",
    margin: "20px"
  }
}));

export default function BecomeAProvider(props) {
  const classes = useStyles();

  const initialState = {
    location: "",
    title: "",
    specs: "",
    rate: "0",
    minute: "minute"
  };
  const [user, setUser] = useState(initialState);

  const handleClick = input => e => {
    e.preventDefault();

    switch (input) {
      case 'upload':
        return;
      case 'googlePhoto':
        return;
      case 'fbPhoto':
        return;
      case 'desktopPhoto':
        return;
      case 'youtubePhoto':
        return;
      case 'desktopPhoto':
        return;
      case 'youtubePhoto':
        return;
      case 'cancel':
        return setUser(initialState);;
      case 'updateProfile':
        return;
      default:
        return "hii";
    }

  }
  const handleChange = input => e => {
    setUser({ ...user, [input]: e.target.value });
  };

  return (
    <Paper className={classes.container}>

      <Grid container spacing={2}>
        <Grid item lg={6} className={classes.uploadDiv}>
          <Fab className={classes.cameraDiv}>
            <CameraAlt className={classes.cameraIcon} onClick={handleClick("upload")} />
          </Fab>
          <span hidden>
            <Button size="small" color="primary" startIcon={<Facebook />} onClick={handleClick("googlePhoto")}>
              Use my google photo
            </Button>

            <Button size="small" color="primary" startIcon={<Facebook />} onClick={handleClick("fbPhoto")}>
              Use my Facebook photo
            </Button>
          </span>
          <Button size="small" color="primary" startIcon={<Publish />} onClick={handleClick("desktopPhoto")}>
            Upload a photo
            </Button>
          <Divider className={classes.dividerVertical} />
          <Button size="small" color="primary" startIcon={<YouTube />} onClick={handleClick("youtubePhoto")}>
            Add a youtube intro video
            </Button>
        </Grid>
        <Grid item lg={6}>
          <div className={classes.labelDiv}>
            <Typography color="textPrimary">Your location</Typography>
            <Typography color="primary"> * </Typography>
            <Typography color="textSecondary">
              (city, country, or postal code)
              </Typography>
          </div>

          <TextField
            variant="filled"
            required
            fullWidth
            autoFocus
            value={user.location}
            onChange={handleChange("location")}
          />
          <div className={classes.labelDiv}>
            <Typography color="textPrimary">Professional Title </Typography>
            <Typography color="primary"> * </Typography>
            <Typography color="textSecondary">
              (i.e. CEO, Product Marketer, Skills Coach)
              </Typography>
          </div>

          <TextField
            variant="filled"
            required
            fullWidth
            value={user.title}
            onChange={handleChange("title")}
          />

          <div className={classes.labelDiv}>
            <Typography color="textPrimary">Areas of expertise </Typography>
            <Typography color="textSecondary">
              (i.e. sales, marketing, budgeting)
              </Typography>
          </div>

          <TextField
            variant="filled"
            required
            fullWidth
            value={user.specs}
            onChange={handleChange("specs")}
          />
          <div className={classes.labelDiv}>
            <Typography color="textPrimary">
              Your rate for consulting
              </Typography>
          </div>
          <div className={classes.rateDiv}>

            <TextField
              label="$"
              variant="filled"
              color="primary"
              className={classes.dollarTextfield}
              value={user.rate}
              onChange={handleChange("rate")}
            />


            <FormControl
              variant="filled"
              color="primary"
              className={classes.minuteTextfield}
            >
              <InputLabel
                htmlFor="filled-age-simple"
                color="primary"
              ></InputLabel>
              <Select value={user.minute} onChange={handleChange("minute")}>
                <MenuItem value="minute">minute</MenuItem>
                <MenuItem value="hour">hour</MenuItem>
                <MenuItem value="day">day</MenuItem>
              </Select>
            </FormControl>
          </div>
        </Grid>
        <Grid item lg={12} className={classes.buttonDiv}>
          <Button
            component="button"
            color="primary"
            className={classes.margin}
            margin="dense"
            onClick={handleClick("cancel")}
          >
            CANCEL
            </Button>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            className={classes.buttonStyle}
            onClick={handleClick("updateProfile")}
          >
            UPDATE PROFILE
            </Button>
        </Grid>
      </Grid>

    </Paper >
  );
}
