import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box
} from "@material-ui/core";

import {
  Notifications,
  Inbox,
  Message,
  Person,
  Settings,
  Apps,
  ExitToApp
} from "@material-ui/icons";

import Badge from "@material-ui/core/Badge";

import { Link } from "@reach/router";

import Logo from "./shared/Logo";

const drawerWidth = 230;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  icon: {
    height: 64,
    width: 64,
    color: "white"
  },
  notification: {
    flex: "0",
    backgroundColor: theme.palette.primary.main,
    padding: 0
  },
  logo: {
    width: 170,
    textAlign: "center"
  },
  logoWrapper: {
    textAlign: "center",
    padding: "30px 0 0 0"
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  drawerPaper: {
    width: drawerWidth
  }
}));

function Nav(props) {
  const classes = useStyles();
  const { notifications, messages, appointments } = props;

  function ListItemLink(props) {
    const { icon, primary, to } = props;

    const renderLink = React.useMemo(
      () =>
        React.forwardRef((itemProps, ref) => (
          // With react-router-dom@^6.0.0 use `ref` instead of `innerRef`
          // See https://github.com/ReactTraining/react-router/issues/6056
          <Link to={to} {...itemProps} innerRef={ref} />
        )),
      [to]
    );

    return (
      <li>
        <ListItem button component={renderLink}>
          {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
          <ListItemText primary={primary} />
        </ListItem>
      </li>
    );
  }

  return (
    <div className={classes.root}>
      <nav className={classes.drawer}>
        <Box className={classes.logoWrapper}>
          <Logo onClick={() => props.history.push("/knowledge_suite")} />
        </Box>
        <List dense aria-label="profile meetings">
          <ListItemLink to="profile" icon={<Person />} primary="PROFILE" />
          <ListItemLink
            to="meetings"
            icon={
              <Badge
                badgeContent={appointments.length}
                max={99}
                color="primary"
              >
                <Inbox />
              </Badge>
            }
            primary="MEETINGS"
          />
          <ListItemLink
            to="messages"
            icon={
              <Badge badgeContent={messages.length} max={99} color="primary">
                <Message />
              </Badge>
            }
            primary="MESSAGES"
          />
          <ListItemLink
            to="notifications"
            icon={
              <Badge
                badgeContent={notifications.length}
                max={99}
                color="primary"
              >
                <Notifications />
              </Badge>
            }
            primary="NOTIFICATIONS"
          />
          <ListItemLink to="dashboard" icon={<Apps />} primary="DASHBOARD" />
          <ListItemLink to="settings" icon={<Settings />} primary="SETTINGS" />
          <ListItemLink to="/logout" icon={<ExitToApp />} primary="LOGOUT" />
        </List>

        <ul>
          <li>
            <Link to="provider-receive-request">Provider Receive Request</Link>
          </li>

          <li>
            <Link to="provider-send-meeting-invite">
              Provider Send Meeting Invite
            </Link>
          </li>

          <li>
            <Link to="call-now">Call Now</Link>
          </li>

          <li>
            <Link to="group-call-in-progress">Group Call in Progress</Link>
          </li>

          <li>
            <Link to="one-on-one">One on One Call Ready</Link>
          </li>

          <li>
            <Link to="call-confirm-and-launch">Call Confirm and Launch</Link>
          </li>

          <li>---- VIDEO CHAT ---</li>
          <li>
            <Link to="/chat">Video Chat</Link>
          </li>
        </ul>
      </nav>
    </div>
  );
}

const mapState = ({
  notifications: { notifications = [], notificationsLoaded },
  appointments,
  messages,
  user: { user }
}) => ({
  messages,
  notifications,
  notificationsLoaded,
  appointments,
  user
});

export default connect(
  mapState,
  {}
)(Nav);
