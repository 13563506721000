import axios from "../helpers/fetch";
import actionTyper from "redux-actiontyper";

export const { STORE_CONVERSATIONS, STORE_CONVERSATION } = actionTyper();

export const fetchConversations = () => dispatch => {
  axios
    .get("/knowledge_suite/web_chat/get_user_conversations.json")
    .then(({ data: conversations }) => {
      dispatch({
        type: STORE_CONVERSATIONS,
        payload: { conversations }
      });
    });
};

export const fetchConversation = uuid => dispatch => {
  axios
    .get("/knowledge_suite/web_chat/get_user_conversation.json", {
      uuid
    })
    .then(({ data: conversation }) => {
      dispatch({
        type: STORE_CONVERSATION,
        payload: { conversation }
      });
    });
};

// export const sendMessage = () => dispatch => {
//   axios.post(urlBuilder("/messages.json")).then(({ data: messages }) => {
//     dispatch({
//       type: MESSAGE_CREATED,
//       payload: { messages }
//     });
//   });
// };

/*
 *   Temporary Javascript methods for messages until TWILIO CHAT is implemented app side
 */

// const deleteChannels = () => {
//   axios
//     .post("/twilio_messaging/get_twilio_chat_token", {
//       identity: user.username,
//       device: "browser"
//     })
//     .then(response => {
//       Twilio.Chat.Client.create(data.chat_token).then(client => {
//         client.getSubscribedChannels().then(paginator => {
//           for (i = 0; i < paginator.items.length; i++) {
//             var channel = paginator.items[i];
//             channel.delete().then(function(chan) {
//               //    console.log("channel deleted: " + chan.sid);
//             });
//           }
//         });
//       });
//     });
// };
