import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { Provider } from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from "./constants/themes";
import App from "./App";
import { Helmet } from "react-helmet";
import store from "./store";

import "./styles.css";

const rootElement = document.getElementById("root");
ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Helmet title="Magnifi" />
      <App
        originalPath={`${window.location.pathname}${window.location.search}`}
      />
    </ThemeProvider>
  </Provider>,
  rootElement
);
