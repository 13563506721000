import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";

import pic from "../profile_pic.png";

const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    maxWidth: 360
  }
}));

export default function Messaging(props) {
  const classes = useStyles();
  const [user, setUser] = useState({
    ...props.user,
    contact_name: ["jane done", "Steve", "Charles Xavier"]
  });
  const [selectedName, setSelectedName] = React.useState(user.selected_name);

  const handleListItemClick = (event, name) => {
    setSelectedName(name);
  };

  return (
    <Box className={classes.container}>
      <List>
        {user.contact_name.map((name, index) => (
          <ListItem
            button
            selected={selectedName === name}
            onClick={event => handleListItemClick(event, index)}
          >
            <ListItemIcon>
              <Avatar alt="Remy Sharp" src={pic} key={index} />
            </ListItemIcon>
            <ListItemText primary={name} key={index} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
