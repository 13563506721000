import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SubscriptionCard, { types } from "./SubscriptionCard";
import Grid from "@material-ui/core/Grid";
import { Typography, Card } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CheckIcon from "@material-ui/icons/Check";

const useStyles = makeStyles(theme => ({
  root: {
    color: "white",
    textAlign: "center",
    minHeight: 400
  },
  feedBack: {
    backgroundColor: "#fff",
    padding: theme.spacing(1)
  }
}));

function SubscriptionPanel(props) {
  const classes = useStyles();

  const perks = [
    "Crystal clear audio/video calls",
    "Mobile and desktop versions",
    "Integrated calling and billing",
    "Calendar integration"
  ];

  const perks2 = [
    "Full access to KnowledgeSuite and multi-use dashboard",
    "Share images and links via chat rooms",
    "Built-in screen sharing",
    "Secure and safe"
  ];

  return (
    <>
      <Grid container spacing={1}>
        <Grid item lg={12}>
          <Card className={classes.feedBack}>
            <Typography variant="h6" component="h2">
              Your Magnifi Subscription expires in 15 Days
            </Typography>
            <Grid container spacing={1}>
              <Grid item lg={6}>
                <Typography>Your subscription expires soon!</Typography>
                <Typography>
                  Choose a package below to stay connected. All plans include
                  the following features:
                </Typography>
              </Grid>
              <Grid item lg={3}>
                <List aria-label="details" dense className={classes.details}>
                  {perks.map(perk => (
                    <ListItem key={perk}>
                      <ListItemIcon>
                        <CheckIcon />
                      </ListItemIcon>
                      <ListItemText primary={perk} />
                    </ListItem>
                  ))}
                </List>
              </Grid>
              <Grid item lg={3}>
                <List aria-label="details" dense className={classes.details}>
                  {perks2.map(perk => (
                    <ListItem key={perk}>
                      <ListItemIcon>
                        <CheckIcon />
                      </ListItemIcon>
                      <ListItemText primary={perk} />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item lg={4}>
          <SubscriptionCard type={types.LITE} />
        </Grid>
        <Grid item lg={4}>
          <SubscriptionCard type={types.PRO} bestValue />
        </Grid>
        <Grid item lg={4}>
          <SubscriptionCard type={types.TEAM} />
        </Grid>
      </Grid>
    </>
  );
}

export { SubscriptionPanel as default };
