import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const width = 60;
const ribbonHeight = 10;

const useStyles = makeStyles(theme => ({
  root: {
    position: "absolute",
    right: 10,
    top: 0
  },
  ribbon: {
    width,
    padding: "5px 0",
    background: theme.palette.primary.main,
    position: "relative",
    display: "block",
    fontWeight: "bold",
    "&:after": {
      content: '""',
      position: "absolute",
      height: 0,
      width: 0,
      bottom: 0.5 - ribbonHeight,
      left: 0,
      borderLeft: `${width / 2}px solid #3caea3`,
      borderRight: `${width / 2}px solid #3caea3`,
      borderBottom: `${ribbonHeight}px solid transparent`
    }
  },
  ribbonShadow: {
    background: "rgba(0,0,0,0.5)",
    position: "absolute",
    top: 3,
    left: 0,
    color: "#000",
    filter: "blur(2px)",
    "&:after": {
      borderLeft: `${width / 2}px solid rgba(0,0,0,0.5)`,
      borderRight: `${width / 2}px solid rgba(0,0,0,0.5)`,
      filter: "blur(2px)"
    }
  }
}));

const Ribbon = props => {
  const classes = useStyles();
  const { text } = props;

  return (
    <div className={classes.root}>
      <span className={clsx(classes.ribbon, classes.ribbonShadow)}>{text}</span>
      <span className={classes.ribbon}>{text}</span>
    </div>
  );
};

export default Ribbon;
