const isTokenStillValid = parsedToken => {
  const currentTime = Math.floor(Date.now() / 1000);
  return currentTime < parsedToken.exp;
};

const parseJWT = jwtString => JSON.parse(atob(jwtString.split(".")[1]));

const auth = {
  user: {},
  isAuthenticated: () => {
    const token = window.localStorage.getItem("AuthorizationToken");
    if (token === null || token === "undefined" || token === "") return false;
    if (isTokenStillValid(parseJWT(token))) return true;
    return false;
  }
};

export default auth;
