import React from "react";
import { connect } from "react-redux";
import NotificationItem, {
  NotificationTypes
} from "../components/NotificationItem";
import { Grid, Paper, Typography, Box } from "@material-ui/core";
import { useUser } from "../hooks/user";

const Notifications = props => {
  const { notifications } = props;
  const user = useUser();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <NotificationItem
          title={`Welcome to Magnifi, ${user.first_name}!`}
          message="This is your notification centre. This is where you’ll get a summary of alerts, messages, and other important information."
          type={NotificationTypes.NONE}
        />
      </Grid>
      <Grid item xs={12}>
        {notifications.length > 0 ? (
          notifications.map(notification => (
            <NotificationItem
              type={NotificationTypes.CLOCK}
              data={notification}
            />
          ))
        ) : (
          <Box>
            <Typography>You currently have no notifications.</Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

const mapState = ({
  notifications: { notifications = [], notificationsLoaded },
  user: { user }
}) => ({
  notifications,
  notificationsLoaded,
  user
});

export default connect(
  mapState,
  {}
)(Notifications);
