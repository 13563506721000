import React from "react";
import { default as RegisterForm } from "../components/Register";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  main: {
    margin: "0 auto",
    maxWidth: 700
  }
}));

const Register = props => {
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <RegisterForm {...props} />
    </div>
  );
};

export default Register;
