import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import animation from "./magnifi_M_1.gif";
import { Container, Box, Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  main: {
    height: "100%",
    background:
      "linear-gradient(45deg, rgba(60,174,163,1) 0%, rgba(7,198,152,1) 100%)"
  },
  img: {
    marginTop: 40,
    width: 100
  },
  wrapper: {
    textAlign: "center",
    color: "#fff"
  },
  version: {
    fontSize: 8
  }
}));

const LoadingMessage = () => {
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <Grid container justify="center">
        <Box className={classes.wrapper}>
          <img src={animation} alt="magnifi" className={classes.img} />
          <Typography className={classes.version}>Version 1.0</Typography>
        </Box>
      </Grid>
    </div>
  );
};

export default LoadingMessage;
