import React from "react";
import { Paper, Typography } from "@material-ui/core";

const Settings = props => {
  return (
    <Paper>
      <Typography variant="h6">Settings</Typography>
      <Typography variant="h5">Account Management</Typography>
      <Typography variant="h5">Personal Profile Settings</Typography>
      <Typography>Name</Typography>
      <Typography>Photo</Typography>
      <Typography>Email Address</Typography>
      <Typography>Username</Typography>
      <Typography>Password</Typography>
      <Typography>Location</Typography>
      <Typography>Billing</Typography>
      <Typography variant="h5">Provider profile Settings</Typography>
      <Typography>Edit provider account</Typography>
      <Typography>Customer URL</Typography>
      <Typography>Time Zone</Typography>
      <Typography>Calendar</Typography>
      <Typography>Billing</Typography>
      <Typography>Stripe Account</Typography>
      <Typography>Plan</Typography>
      <Typography variant="h5">Team</Typography>
      <Typography>Applicable to thise who have Magnifi Team</Typography>
    </Paper>
  );
};

export default Settings;
