import { combineReducers } from "redux";
import user from "./user";
import providers from "./providers";
import notifications from "./notifications";
import messages from "./messages";
import conversations from "./conversations";
import appointments from "./appointments";

const reducers = combineReducers({
  user,
  providers,
  notifications,
  messages,
  conversations,
  appointments
});

export default reducers;
