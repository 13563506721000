import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import SearchBar from "./SearchBar";
import { connect } from "react-redux";

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    flexGrow: 1
  }
}));

function Header(props) {
  const classes = useStyles();
  const { user, navigate } = props;

  return (
    <div className={classes.root}>
      <Toolbar>
        <div className={classes.buttonContainer}>
          {!user.is_mind_sharer && (
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                navigate(
                  !user.is_mind_sharer
                    ? "become-a-provider"
                    : "become-a-provider"
                )
              }
            >
              Become A Provider
            </Button>
          )}
          <Button
            color="primary"
            variant="contained"
            onClick={() => navigate("subscriptions")}
          >
            Become A Member
          </Button>
        </div>
        <div>
          <SearchBar navigate={navigate} />
        </div>
      </Toolbar>
    </div>
  );
}

const mapState = ({ user: { user } }) => ({
  user
});

export default connect(
  mapState,
  {}
)(Header);
