import React from "react";
import { Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  color: {
    color: theme.palette.primary.main
  },
  fixed_div: {
    backgroundColor: "#E5870C",
    borderRadius: "0px 0px 11px 11px",
    position: "absolute",
    right: "5px",
    top: "0",
    display: "block",
    padding: "10px",
    color: "#fff",
    textAlign: "center"
  },
  rate_text: {
    top: "13px",
    fontSize: "24px"
  },
  textsmall: {
    top: "42px",
    fontSize: "11px",
    textTransform: "capitalize"
  }
}));

const types = {
  HOUR: "hour",
  MINUTE: "minute"
};

function Sticky(props) {
  const classes = useStyles();
  const { rate, type } = props;

  return (
    <div className={classes.fixed_div}>
      <Typography className={classes.rate_text}>${rate}</Typography>
      <Typography className={classes.textsmall}>
        Per {types[type.toUpperCase()]}
      </Typography>
    </div>
  );
}

Sticky.defaultProps = {
  type: types.HOUR,
  rate: 0
};

export { Sticky as default, types };
