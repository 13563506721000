import axios from "../helpers/fetch";
import actionTyper from "redux-actiontyper";

export const { STORE_APPOINTMENTS, APPOINTMENT_CREATED } = actionTyper();

export const fetchAppointments = () => dispatch => {
  axios.get("/appointments.json").then(({ data: appointments }) => {
    dispatch({
      type: STORE_APPOINTMENTS,
      payload: { appointments }
    });
  });
};

export const scheduleMeeting = () => dispatch => {
  axios.post("/appointments.json").then(({ data: appointments }) => {
    dispatch({
      type: APPOINTMENT_CREATED,
      payload: { appointments }
    });
  });
};
