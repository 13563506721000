import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import ProfileCompact from "../components/ProfileCompact";
import { default as initialUser } from "../models/user";
import CircularProgress from "@material-ui/core/CircularProgress";
import filterByFields from "../helpers/filterByFields";
import { connect } from "react-redux";

const useStyles = makeStyles(theme => ({
  container: {
    padding: "25px 30px"
  }
}));

function SearchResult(props) {
  const classes = useStyles();
  const { filteredProviders, searchText, providersLoaded } = props;

  if (!providersLoaded) return <CircularProgress />;

  return (
    <>
      <Typography>
        {filteredProviders.length} Results - Query: {searchText}
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            {filteredProviders &&
              filteredProviders.map(provider => (
                <Grid key={provider.uuid} item xs={6}>
                  <ProfileCompact user={provider} />
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

const mapState = ({
  providers: { providers = [], searchText, providersLoaded }
}) => ({
  providers,
  searchText,
  providersLoaded,
  filteredProviders: filterByFields(
    providers,
    ["first_name", "last_name"],
    searchText
  )
});

export default connect(
  mapState,
  {}
)(SearchResult);
