import React, { useState } from "react";
import { default as ProfileForm } from "../components/Profile";
import ProfileCompact from "../components/ProfileCompact";
import user from "../models/user";

const Profile = props => {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <ProfileForm
      //  isClientView
      isEditing={isEditing}
      setIsEditing={setIsEditing}
      {...props}
    />
    // <ProfileCompact user={user} />
  );
};

export default Profile;
