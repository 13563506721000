import { useEffect } from "react";
import { navigate } from "@reach/router";

import { connect } from "react-redux";
import { logout } from "../actions";

const Logout = ({ logout }) => {
  useEffect(() => {
    logout();
    navigate("/login", { state: { originalPath: "/" } });
  }, [logout]);

  return null;
};

export default connect(
  () => null,
  { logout }
)(Logout);
