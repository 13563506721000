import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Copyright from "../components/shared/Copyright";
import Logo from "../components/shared/Logo";
import { Typography, Container } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  main: {
    height: "100%",
    background:
      "linear-gradient(45deg, rgba(60,174,163,1) 0%, rgba(7,198,152,1) 100%)"
  },
  header: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: "#fff"
  },
  footer: {
    padding: theme.spacing(2),
    color: "#fff"
  }
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <Container>
        <header className={classes.header}>
          <Logo alt />
          <Typography color="inherit">
            Connecting you with great minds at great rates
          </Typography>
        </header>
        {children}
        <footer className={classes.footer}>
          <Copyright />
        </footer>
      </Container>
    </div>
  );
};

export default MainLayout;
