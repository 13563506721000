import React, { useState, useEffect } from "react";

import {
  makeStyles,
  CardContent,
  Button,
  Typography,
  Grid,
  Box,
  TextField,
  Paper,
  Divider
} from "@material-ui/core";

import Avatar from "@material-ui/core/Avatar";
import Rating from "@material-ui/lab/Rating";
import Chip from "@material-ui/core/Chip";
import { Edit, YouTube } from "@material-ui/icons";
import Sticky from "./shared/Sticky";
import SocialLinks from "./shared/SocialLinks";
import CallMsg from "./shared/CallMsg";
import axios from "axios";
import initialUser from "../models/user";

const useStyles = makeStyles(theme => ({
  color: {
    color: theme.palette.primary.main
  },
  container: {
    display: "flex",
    position: "relative",
    padding: "2px"
  },
  avatar: {
    marginTop: 10,
    width: 167,
    height: 167
  },
  video_btn: {
    marginLeft: 5,
    border: "none"
  },
  star_li: {
    color: theme.palette.primary.main,
    listStyle: "none"
  },
  spacing: {
    margin: `${theme.spacing(2)}px 0`
  },
  icon_div: {
    margin: `0 ${theme.spacing(0.5)}px`,
    display: "flex"
  },
  align_div: {
    display: "inline-flex",
    alignItems: "center"
  },
  rounded_div: {
    margin: `${theme.spacing(2)}px 0`
  },
  icons: {
    margin: "1px",
    display: "flex"
  },
  specs: {
    margin: `0 ${theme.spacing(0.5)}px`
  },
  video_div: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  },
  text1: {
    width: "893px",
    height: "2"
  },
  text2: {
    width: "893px",
    height: "212.43px",
    fontSize: "14px"
  },
  rate_text: {
    top: "13px",
    fontSize: "24px"
  },
  textsmall: {
    top: "42px",
    fontSize: "11px"
  },
  pos_li: {
    display: "flex",
    flexDirection: "row"
  },
  pos_li1: {
    fontSize: "16px",
    marginLeft: "10px",
    marginTop: "4px"
  },
  call_div: {
    display: "flex",
    alignItems: "center"
  },
  divider_vert: {
    margin: "20px",
    height: "20px",
    border: "1px  #ACB6B2"
  },
  avatar_small: {
    margin: 10,
    width: 64,
    height: 64
  },
  grid_margin: {
    marginTop: "-30px"
  },
  margin: {
    margin: "20px"
  }
}));

function Profile(props) {
  const classes = useStyles();
  const [user, setUser] = useState({ ...initialUser, ...props.user });
  const { isEditing, isClientView, current_user } = props;

  const handleChange = input => e => {
    setUser({ ...user, [input]: e.target.value });
  };

  const handleChatClick = () => {
    //getOrCreateUserConversations
    axios
      .post("/knowledge_suite/web_chat/create_conversations", {
        usernames: [user.username],
        emails: [user.email],
        uuids: [user.uuid],
        name: `Conversation between ${user.get_full_name} and ${current_user.get_full_name}`
      })
      .then(response => {
        window.location.href = "/knowledge_suite/web_chat";
      });
  };

  const handleClick = input => e => {
    e.preventDefault();

    switch (input) {
      case "editSocialLink":
        return;
      case "editProfile":
        return props.setIsEditing(true);
      case "saveProfile":
        return props.setIsEditing(false);
      case "cancelEditProfile":
        return props.setIsEditing(false);
      case "chipDelete":
        return;

      default:
        return "";
    }
  };

  const onOpenProfileClick = () => {
    window.location = `/profile/${user.username}`;
  };

  console.log(user);
  if (!user) return null;

  return (
    <Paper className={classes.container}>
      <Sticky rate={user.video_call_rate} />
      <Grid container className={classes.margin}>
        <Grid container>
          <Grid item xs={2}>
            <div className={classes.video_div}>
              <Avatar
                alt="Profile Pic"
                src={user.thumbnail_url}
                className={classes.avatar}
              />

              <>
                <div className={classes.video_div}>
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    className={classes.video_btn}
                    startIcon={<YouTube />}
                    onClick={() => alert("open youtube preview")}
                  >
                    Intro video
                  </Button>
                </div>

                <div className={classes.video_div}>
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    className={classes.video_btn}
                    startIcon={<Edit />}
                  >
                    Photo
                  </Button>
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    className={classes.video_btn}
                    startIcon={<Edit />}
                  >
                    Intro video
                  </Button>
                </div>
              </>
            </div>
          </Grid>

          <Grid item xs={8}>
            <CardContent>
              <Typography variant="h4">
                {user.first_name} {user.last_name}
              </Typography>

              <Box className={classes.pos_li}>
                <Typography variant="subtitle1">
                  {/* {user.primary_category.name} */}
                </Typography>

                {/* {user.position.slice(1).map(position => (
                    <li
                      variant="subtitle1"
                      key={position}
                      className={classes.pos_li1}
                    >
                      {position}
                    </li>
                  ))} */}
              </Box>
              {(user.city_name || user.city_country_code) && (
                <Typography color="textSecondary">
                  {user.city_name} {user.city_country_code}
                </Typography>
              )}

              <div className={classes.align_div}>
                <Rating value={1} readOnly className={classes.color} />
                <span className={classes.color}>{55}</span>
              </div>

              {user.default_short_bio && (
                <>
                  <Typography
                    variant="subtitle2"
                    fontStyle="italic"
                    className={classes.spacing}
                  >
                    {user.short_bio}
                  </Typography>
                  <TextField
                    variant="filled"
                    required
                    fullWidth
                    autoFocus
                    value={user.short_bio}
                    onChange={handleChange("short_bio")}
                  />
                </>
              )}
            </CardContent>
          </Grid>
        </Grid>

        <Grid container className={classes.grid_margin}>
          <Grid item xs={2}></Grid>
          <Grid item xs={10}>
            <CardContent>
              {user.keywords && (
                <>
                  <div className={classes.spacing}>
                    {user.keywords.map(specs => (
                      <Chip
                        className={(classes.chip, classes.specs)}
                        key={specs}
                        label={specs}
                        color="secondary"
                        variant="outlined"
                      />
                    ))}
                  </div>
                  <div className={classes.spacing}>
                    {user.keywords.map(category => (
                      <Chip
                        label={category}
                        className={(classes.chip, classes.specs)}
                        key={user.id}
                        onDelete={handleClick("chipDelete")}
                        color="secondary"
                        variant="outlined"
                      />
                    ))}
                    <Button
                      size="small"
                      variant="outlined"
                      color="primary"
                      className={classes.video_btn}
                      startIcon={<Edit />}
                    >
                      Add Tags
                    </Button>
                  </div>
                </>
              )}

              <>
                <>
                  <div className={classes.spacing}>
                    <SocialLinks user={user} />
                  </div>

                  <div className={classes.spacing}>
                    <div className={classes.icon_div}>
                      <Grid container spacing={1}>
                        <Grid item>
                          <SocialLinks user={user} />
                        </Grid>
                        <Grid item>
                          <Button
                            size="small"
                            variant="outlined"
                            color="primary"
                            className={classes.video_btn}
                            startIcon={<Edit />}
                            onClick={handleClick("editSocialLink")}
                          >
                            SOCIAL LINKS
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </>

                <Grid container className={classes.call_div}>
                  <Grid item>
                    <CallMsg user={user} />
                  </Grid>
                  <Divider
                    orientation="vertical"
                    className={classes.divider_vert}
                  />
                  <Grid item>
                    <SocialLinks user={user} />
                  </Grid>
                </Grid>
              </>

              {user.bio && (
                <>
                  <Typography variant="subtitle1">{user.bio}</Typography>
                  <TextField
                    variant="filled"
                    required
                    fullWidth
                    multiline
                    rowsMax="4"
                    value={user.bio}
                    onChange={handleChange("bio")}
                  />
                </>
              )}
              <>
                <>
                  <Button
                    variant="contained"
                    startIcon={<Edit />}
                    onClick={handleClick("editProfile")}
                    color="primary"
                  >
                    Edit Profile
                  </Button>

                  <Button
                    variant="contained"
                    onClick={handleClick("saveProfile")}
                    color="primary"
                  >
                    Save Profile
                  </Button>

                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    className={classes.video_btn}
                    onClick={handleClick("cancelEditProfile")}
                  >
                    Cancel
                  </Button>
                </>
              </>
            </CardContent>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default Profile;
