import { STORE_MESSAGES } from "../actions";

const initialState = {
  messages: [],
  messagesLoaded: false
};

function messages(state = initialState, action) {
  switch (action.type) {
    case STORE_MESSAGES: {
      return {
        ...state,
        messages: [...action.payload.messages],
        messagesLoaded: true
      };
    }
    default:
      return state;
  }
}

export default messages;
