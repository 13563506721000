import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import history from "../helpers/history";
import { connect } from "react-redux";

import { searchProviders } from "../actions";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 2,
    color: theme.palette.primary.main,
    width: 24,
    height: 25
  },
  divider: {
    height: 28,
    margin: 4
  }
}));

function SearchBar(props) {
  const classes = useStyles();
  const { searchProviders, navigate } = props;

  const handleChange = e => {
    navigate("providers");
    searchProviders(e.target.value);
  };

  return (
    <Paper className={classes.root}>
      <SearchIcon className={classes.iconButton} />
      <InputBase
        className={classes.input}
        placeholder="Search"
        inputProps={{ "aria-label": "search" }}
        onChange={handleChange}
      />
    </Paper>
  );
}

export default connect(
  null,
  { searchProviders }
)(SearchBar);
