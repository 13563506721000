import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useConversations } from "../../hooks/conversations";

import {
  Typography,
  IconButton,
  Fab,
  Dialog,
  DialogActions,
  DialogContentText,
  Paper,
  DialogTitle,
  DialogContent,
  Grid,
  Button,
  TextField
} from "@material-ui/core";
import {
  AddCommentOutlined,
  AttachFile,
  ChevronRight
} from "@material-ui/icons";
import ChatList from "./ChatList";
import MessageList from "./MessageList";
import WelcomeMessage from "../WelcomeMessage";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex"
  },
  sidebar: {
    flex: "0"
  },
  chats: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    width: "276px",
    margin: "10px"
  },
  icons: {
    display: "flex",
    flexDirection: "column",
    justify: "space-between"
  },
  grid: {
    margin: "10px"
  }
}));

function ChatPage(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [user, setUser] = useState({
    ...props.user,
    selected_name: "Steve",
    user2: "Jane Doe",
    message: ["hi", "hello"],
    text_message:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim vel amet, orci non lectus morbi sit."
  });

  const conversations = useConversations();

  const handleChange = input => e => {
    setUser({ ...user, [input]: e.target.value });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDialog = () => {
    setOpen(true);
  };

  const [openWelcome, setOpenWelcome] = React.useState(true);

  const handleClickOpen = () => {
    setOpenWelcome(true);
  };

  const handleClickClose = () => {
    setOpenWelcome(false);
  };

  const { isShowWelcomeMessage } = props;

  return (
    <>
      <WelcomeMessage open={openWelcome} onClose={handleClickClose} />
      <Paper className={classes.container}>
        <Paper className={classes.sidebar}>
          <div className={classes.chats}>
            <Typography variant="h5">Chats</Typography>
            <IconButton aria-label="delete" onClick={handleDialog}>
              <AddCommentOutlined color="primary" />
            </IconButton>
          </div>
          <ChatList user={user} />
        </Paper>

        <Grid container spacing={1} className={classes.grid}>
          <Grid item xs={12}>
            {user.message.map((msg, index) => (
              <MessageList msg={msg} key={index} user={user} />
            ))}
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={10}>
                <TextField
                  id="standard-multiline-static"
                  value={user.text_message}
                  multiline
                  rows="4"
                  fullWidth
                  className={classes.textField}
                  onChange={handleChange("text_message")}
                  margin="normal"
                />
              </Grid>
              <Grid item className={classes.icons}>
                <IconButton>
                  <AttachFile color="primary" />
                </IconButton>
                <Fab color="primary">
                  <ChevronRight />
                </Fab>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Send a new message</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <TextField
                variant="filled"
                autoFocus
                onChange={handleChange("selected_name")}
                id="name"
                label="Enter a name"
                type="name"
                fullWidth
                color="primary"
              />
            </DialogContentText>
            <ChatList user={user} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </>
  );
}

ChatPage.defaultProps = {
  isShowWelcomeMessage: false
};

export default ChatPage;
