import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Copyright from "../components/shared/Copyright";
import { Box } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  main: {
    background: "#F9F9F9"
  },
  footer: {
    padding: theme.spacing(2),
    color: "#000"
  }
}));

const VideoChatLayout = props => {
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <Box>
        {props.children}
        <footer className={classes.footer}>
          <Copyright color="" />
        </footer>
      </Box>
    </div>
  );
};

export default VideoChatLayout;
