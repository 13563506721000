import actionTyper from "redux-actiontyper";
import axios from "../helpers/fetch";

export const { STORE_NOTIFICATIONS } = actionTyper();

export const fetchNotifications = () => dispatch => {
  axios.get("/notifications.json").then(({ data: notifications }) => {
    dispatch({
      type: STORE_NOTIFICATIONS,
      payload: { notifications }
    });
  });
};
