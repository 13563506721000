import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

const useStyles = makeStyles(theme => ({
  icon: {
    height: 64,
    width: 64,
    color: "white"
  },
  notification: {
    flex: "0",
    backgroundColor: theme.palette.primary.main,
    padding: 0
  },
  card: {
    display: "flex"
  },
  content: {
    flex: 1
  }
}));

const initialState = {
  type: "clock",
  title: "Card Title",
  message: "Ask a simple questions to engage with the user."
};

function NotificationItem(props) {
  const classes = useStyles();
  const state = {
    ...initialState,
    ...props
  };
  return (
    <Card className={classes.card}>
      {state.type && state.type.toLowerCase() !== "none" && (
        <div className={classes.notification}>
          <IconButton aria-label="notification" disabled>
            <GetIcon type="clock" className={classes.icon} />
          </IconButton>
        </div>
      )}
      <div className={classes.content}>
        <CardContent>
          <Typography component="h4" variant="h4">
            {state.title}
          </Typography>
          <Typography variant="subtitle1">{state.message}</Typography>
        </CardContent>
        <Divider />
        <CardContent>
          <Button color="primary">Action 1</Button>
          <Button color="primary">Action 2</Button>
        </CardContent>
      </div>
    </Card>
  );
}

const types = {
  NONE: "",
  CLOCK: "clock",
  INFO: "info"
};

const icons = {
  clock: <AccessTimeIcon />,
  info: <ErrorOutlineIcon />
};

const GetIcon = ({ type, ...props }) => React.cloneElement(icons[type], props);

export { NotificationItem as default, types as NotificationTypes };
