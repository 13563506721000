import React, { useState } from "react";
import {
  Typography,
  makeStyles,
  Box,
  Button,
  Card,
  Paper
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "5px"
  },

  caption: {
    fontSize: "12px",
    margin: "0px 53px"
  },
  rectangle: {
    borderLeft: "23px solid transparent",
    borderRight: "23px solid transparent",
    borderBottom: "23px solid white",
    position: "absolute",
    marginTop: "-69px"
  }
}));

function CallNowPopup(props) {
  const classes = useStyles();

  const { first_name, video_call_rate } = props.user;

  const [user, setUser] = useState({
    first_name,
    call_rate: `${video_call_rate}/minute`,
    message: "Your account will be charged once the call has ended."
  });

  return (
    <Card className={classes.container}>
      <Box className={classes.rectangle}></Box>
      <Typography variant="h5">{user.first_name} is Online</Typography>
      <Typography color="error" variant="h4">
        {user.call_rate}
      </Typography>
      <Box>
        <Typography className={classes.caption} align="center">
          {user.message}
        </Typography>
      </Box>
    </Card>
  );
}

export { CallNowPopup as default };
