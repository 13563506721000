import axios from "../helpers/fetch";
import actionTyper from "redux-actiontyper";

export const { STORE_MESSAGES, MESSAGE_CREATED } = actionTyper();

export const fetchMessages = () => dispatch => {
  axios.get("/messages.json").then(({ data: messages }) => {
    dispatch({
      type: STORE_MESSAGES,
      payload: { messages }
    });
  });
};

export const sendMessage = () => dispatch => {
  axios.post("/messages.json").then(({ data: messages }) => {
    dispatch({
      type: MESSAGE_CREATED,
      payload: { messages }
    });
  });
};
