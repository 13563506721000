import * as yup from "yup";

const schema = yup.object().shape({
  first_name: yup
    .string()
    .min(3)
    .max(30)
    .required(),
  last_name: yup
    .string()
    .min(3)
    .max(30)
    .required(),
  email: yup
    .string()
    .email()
    .required(),
  password: yup
    .string()
    .min(3)
    .max(30)
    .required()
});

const validate = obj => schema.validate(obj, { abortEarly: false });

export default validate;
