import React, { useState, useEffect } from "react";
import LoadingMessage from "../components/LoadingMessage";

const withSplashScreen = WrappedComponent => props => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (process.env.NODE_ENV !== "development") setLoading(true);

    const id = setTimeout(() => {
      setLoading(false);
    }, 2500);
  }, []);

  // while checking user session, show "loading" message
  if (loading) return <LoadingMessage />;
  // otherwise, show the desired route

  return <WrappedComponent {...props} />;
};

export default withSplashScreen;
