import axios from "axios";
import actionTyper from "redux-actiontyper";

export const { STORE_PROVIDERS, SEARCH_PROVIDERS } = actionTyper();

export const fetchProviders = () => dispatch => {
  axios
    .get("/searches.json", { search_string: "" })
    .then(({ data: providers }) => {
      dispatch({
        type: STORE_PROVIDERS,
        payload: { providers }
      });
    });
};

export const searchProviders = text => dispatch => {
  dispatch({
    type: SEARCH_PROVIDERS,
    payload: { text }
  });
};
