import { STORE_APPOINTMENTS } from "../actions";

const initialState = {
  appointments: [],
  appointmentsLoaded: false
};

function appointments(state = initialState, action) {
  switch (action.type) {
    case STORE_APPOINTMENTS: {
      return {
        ...state,
        appointments: [...action.payload.appointments],
        appointmentsLoaded: true
      };
    }
    default:
      return state;
  }
}

export default appointments;
