import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CheckIcon from "@material-ui/icons/Check";
import { Divider } from "@material-ui/core";
import Ribbon from "./shared/Ribbon";
import clsx from "clsx";

const types = {
  LITE: "lite",
  PRO: "pro",
  TEAM: "team"
};

const options = {
  [types.LITE]: {
    title: "Lite",
    price: {
      header: "For the solo knowledge Provider",
      fee: "9%",
      copy: "Process fee"
    },
    perks: [
      "Unlimited 1:1 calls",
      "Provider sets provider rate and schedule",
      "Personal marketing page and unique URL"
    ],
    button: "subscribe",
    theme: {}
  },
  [types.PRO]: {
    title: "Pro",
    price: {
      header: "For the growing solo knowledge Provider",
      fee: "$19.99",
      copy: "Per Month",
      legal: "+ 3.5% processing fee per paid call"
    },
    perks: [
      "Unlimited 1:1 and group meetings (per minute billing, flat rate or free)",
      "Provider sets provider rate and schedule",
      "Personal marketing page and unique URL",
      "Analytic dashboard"
    ],
    button: "subscribe",
    theme: {}
  },
  [types.TEAM]: {
    title: "Team",
    price: {
      header: "For growing businesses",
      fee: "$19.99",
      copy: "Per Provider/Month",
      legal: "+ 3.5% processing fee per paid call"
    },
    perks: [
      "Unlimited 1:1 and group meetings (per minute billing, flat rate or free)",
      "Business sets provider rate and schedule",
      "Business and provider marketing pages and unique URLs",
      "Business Analytic dashboard",
      "Sales performance leaderboard",
      "Custom Branding"
    ],
    button: "subscribe",
    theme: {}
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    color: "white",
    textAlign: "center",
    height: "100%",
    position: "relative"
  },
  icon: {
    height: 64,
    width: 64,
    color: "white"
  },
  notification: {
    flex: "0",
    backgroundColor: theme.palette.primary.main,
    padding: 0
  },
  card: {
    display: "flex"
  },
  content: {
    flex: 1
  },
  logo: {
    width: 170
  },
  details: {
    color: "black"
  },
  header: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.tertiary.dark
  },
  price: {
    color: "#fff",
    minHeight: 165,
    backgroundColor: theme.palette.tertiary.main
  },
  [types.LITE]: {
    "& $header": {
      backgroundColor: theme.palette.primary.dark
    },
    "& $price": {
      backgroundColor: theme.palette.primary.main
    }
  },
  [types.PRO]: {
    "& $header": {
      backgroundColor: theme.palette.tertiary.dark
    },
    "& $price": {
      backgroundColor: theme.palette.tertiary.main
    }
  },
  [types.TEAM]: {
    "& $header": {
      backgroundColor: theme.palette.secondary.dark
    },
    "& $price": {
      backgroundColor: theme.palette.secondary.main
    }
  }
}));

const getClass = (classes, type) => {
  return classes[type];
};

function SubscriptionCard(props) {
  const classes = useStyles();
  const { type, bestValue } = props;
  const option = { ...options[type] };

  return (
    <Card className={clsx(classes.root, getClass(classes, type))}>
      {bestValue && <Ribbon text="BEST VALUE" />}
      <CardContent className={classes.header}>
        <Typography variant="h6" component="h2">
          {option.title}
        </Typography>
      </CardContent>
      <CardContent className={classes.price}>
        <Typography>{option.price.header}</Typography>
        <Typography variant="h2" component="h2">
          {option.price.fee}
        </Typography>
        <Typography>{option.price.copy}</Typography>
        {option.price.legal && <Typography>{option.price.legal}</Typography>}
      </CardContent>
      <CardContent>
        <List aria-label="details" dense className={classes.details}>
          {option.perks.map(perk => (
            <ListItem key={perk}>
              <ListItemIcon>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText primary={perk} />
            </ListItem>
          ))}
        </List>
      </CardContent>
      {type !== types.TEAM ? (
        <CardContent>
          <Button color="primary" variant="contained">
            {option.button}
          </Button>
        </CardContent>
      ) : (
        <>
          <Divider />
          <CardContent>
            <Button variant="primary">Request Demo</Button>
            <Button variant="primary">Contact Us</Button>
          </CardContent>
        </>
      )}
    </Card>
  );
}

export { SubscriptionCard as default, types };
